/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0e5863f8-de60-46cc-ac97-57dd7390b5e5",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "7fddef5332af4829900d2fa1e76db64a",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
