//------------------------------------------------------------------------------
//	DDK.Design Custom URL Shortener
//
//	
//------------------------------------------------------------------------------

// Amplify Modules
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';

// Configure Amplify
import awsmobile from "./aws-exports";
Amplify.configure(awsmobile);

// Redirect list
let RedirectList = [];

// Redirect Path Prefix
let RedirectPathPrefix = "/go/";

//------------------------------------------------------------------------------
//	Redirect Analytics
//		
//------------------------------------------------------------------------------
function RedirectAnalytics(src, action = undefined)
{
	let msg = (src == undefined) ? "[missing]" : src;
	let name = (action == undefined) ? "Go" : action;
	
	let evt = {name:name, attributes:{src:msg}};
	try {
		//console.log(JSON.stringify(evt));
		Analytics.record(evt);
	}
	catch (err) {}
}

//------------------------------------------------------------------------------
//	Get Redirect Source
//		Looks at the URL path and the hash to find the short URL
//------------------------------------------------------------------------------
function GetRedirectSrc()
{
	var hash = location.hash.substr(1);
	var path = location.pathname;
	var src = undefined;
	var len = RedirectPathPrefix.length;

	// First see if the path start with the prefix. If so, then everything after is the src
	if (path.length > len && path.substr(0,len).toLowerCase() == RedirectPathPrefix.toLowerCase())
		src = path.substring(len);
	// Next check the hash. Any hash will be considered the src
	else if (hash != undefined && hash.length > 0)
		src = hash;
	else
		src = undefined;

	return src;
}

//------------------------------------------------------------------------------
//	Build Redirect List
//		Hard-coded table of short URLs, descriptions, and long URLs
//------------------------------------------------------------------------------
function BuildRedirectList()
{
	// Simple convenience function
	function a(src, desc, dst) { RedirectList.push({src, desc, dst}) }
	
	a("Polygonia", "Polygonia Design", "https://Polygonia.design");
	a("tools", "Gfx Design Tools", "https://Polygonia.design/tools/index.html");
	a("test", "Sample part in OnShape", "https://cad.onshape.com/documents/c2c60fb28f95bb0dcde07cb7/w/7f1c03625fbebdf60efd8d84/e/67d0d1b44f5b17ca2a7f930f");
	a("tvt", "The Vapor Trail", "https://TheVaporTrail.com");
	a("cb", "Polygonia Coloring Book", "https://www.amazon.com/dp/1984008579/");
}

//------------------------------------------------------------------------------
//	Lookup Redirect Destination
//		Search the redirection table to find a destination
//------------------------------------------------------------------------------
function LookupRedirectDst(src)
{
	var match = RedirectList.find(e => (e.src.localeCompare(src, 'en', { sensitivity: 'base' }) == 0));
	
	return match;
}

//------------------------------------------------------------------------------
//	Toggle Entry URL
//		Show or hide the destination URL for a list entry
//------------------------------------------------------------------------------
function ToggleEntryURL(evt)
{
	let t = evt.currentTarget;
	let p = t.parentNode;
	
	p.classList.toggle("app-entry-box");
	
	let elist = p.getElementsByClassName("app-toggle-item");
	for (var i = 0; i < elist.length; i++)
		elist[i].classList.toggle("hide-element");
}

//------------------------------------------------------------------------------
//	Show Message
//		Display a temporary message
//------------------------------------------------------------------------------
function ShowMessage(message, forElement = undefined, options = undefined)
{
	// Create a div that will be shown as a "banner", set the message, and
	// add it to the body of the document. It will be displayed with the
	// animation ("fade in") that is part of the .message-banner class
	var banner = document.createElement("div");
	banner.classList.add("message-banner");

	// 2019.10.14: Added options
	if (options != undefined && options.size == "small")
		banner.classList.add("message-banner-small");
	if (options != undefined && options.position == "bottom")
		banner.classList.add("message-banner-bottom");

	banner.innerHTML = message;
	
	let bannerParent = (forElement == undefined) ? document.body : forElement.parentNode;
	
	if (forElement != undefined)
		banner.classList.add("message-banner-local");
		
	bannerParent.appendChild(banner); 

	// This clean-up function will be called by the timer, below. It will
	// add a "fade out" animation and an animationend listener. The listener
	// will simply remove the banner div from the document.
	function cleanUp(banner) {
		banner.classList.add("message-banner-fadeout");
		banner.addEventListener("animationend", function(evt) {bannerParent.removeChild(evt.target)}, false);		
	}
	setTimeout(cleanUp, 1000, banner);
}

//------------------------------------------------------------------------------
//	Copy URL
//		Copy to the clipboard the URL stored as a data attribute in the element
//------------------------------------------------------------------------------
function CopyURL(evt)
{
	let t = evt.currentTarget;
	
	// Create a shortened URL
	// Note that the target (t) has the short URL as a data attribute
	let src = location.href + RedirectPathPrefix.substr(1) + t.dataset.src;
	
	try {
		// Create and append a text area so we can copy text
		const el = document.createElement('textarea');
		el.value = src;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		ShowMessage("copied", t);
		
		RedirectAnalytics(t.dataset.src, "Copy");
	}
	catch (err) {
	}
}

//------------------------------------------------------------------------------
//	Show Redirect List
//		Populate the DOM with a list of all of the URLs known by the app
//
//		<div class="app-entry">
//			<span>description</span>
//			<a href=dstURL>
//				<img src='icon_link.png'></img>
//			</a>
//			<img src='icon_show.png'></img>
//			<div class="app-copy-icon-div"> <!-- For the "Copied" message -->
//				<img src='icon_copy.png'></img>
//			</div>
//			<hr>
//			<span>dstURL</span>
//		</div>
//------------------------------------------------------------------------------
function ShowRedirectList(src)
{
	let listDiv = document.getElementById("ID_List");
	
	for (var i = 0; i < RedirectList.length; i++)
	{
		let r = RedirectList[i];
		
		let div = document.createElement("div");
		div.classList.add("app-entry");
		
		let span = document.createElement("span");
		span.innerHTML = r.desc;
		div.appendChild(span);
		
		let a = document.createElement("a");
		a.setAttribute("href", r.dst);
		let img = document.createElement("img");
		img.classList.add("app-icon");
		img.src = "icon_link.png";
		a.appendChild(img);
		
		div.appendChild(a);
		
		let showImg = document.createElement("img");
		showImg.classList.add("app-icon");
		showImg.src = "icon_show.png";
		showImg.addEventListener("click", ToggleEntryURL);
		div.appendChild(showImg);

		let divCopy = document.createElement("div");
		divCopy.classList.add("app-copy-icon-div");
		
		let copyImg = document.createElement("img");
		copyImg.classList.add("app-icon");
		copyImg.src = "icon_copy.png";
		copyImg.dataset.src = r.src;
		copyImg.addEventListener("click", CopyURL);
		divCopy.appendChild(copyImg);
		div.appendChild(divCopy);

		let hr = document.createElement("hr");
		hr.classList.add("hide-element");
		hr.classList.add("app-toggle-item");
		div.appendChild(hr);

		let dstSpan = document.createElement("span");
		dstSpan.innerHTML = r.dst;
		dstSpan.classList.add("hide-element");
		dstSpan.classList.add("app-toggle-item");
		dstSpan.classList.add("app-break-word");
		dstSpan.classList.add("app-dst-url");
		div.appendChild(dstSpan);
								
		listDiv.appendChild(div);
	}
}

//------------------------------------------------------------------------------
//	Get Prior Visit
//		Load any info about the prior visit from local storage
//		and then remove the item for storage
//------------------------------------------------------------------------------
function GetPriorVisit()
{
	var priorVisit = undefined;
	
	try {
		let priorVisitStr = localStorage.getItem("visit");
		priorVisit = (priorVisitStr != undefined) ? JSON.parse(priorVisitStr) : undefined;
	}
	catch (err) {}
		
	localStorage.removeItem("visit");
	
	return priorVisit
}

//------------------------------------------------------------------------------
//	Set Current Visit
//		Record the visit info to local storage
//------------------------------------------------------------------------------
function SetCurrentVisit(src)
{
	let visitInfo = {src:src, ts:Date.now()};
	try {
		localStorage.setItem("visit", JSON.stringify(visitInfo));
	}
	catch (err) {}
	
	return visitInfo;
}

//------------------------------------------------------------------------------
//	Toggle Show Dst
//		
//------------------------------------------------------------------------------
function ToggleShowDst()
{
	document.getElementById("ID_DstInfo").classList.toggle("hide-element");
}

//------------------------------------------------------------------------------
//	WindowLoaded
//		
//------------------------------------------------------------------------------
function WindowLoaded()
{
	BuildRedirectList();
	
	// Get any previous stored info about the most recent visit and then clear it
	let priorVisit = GetPriorVisit();

	// Get the short "URL", either from the path or the hash	
	let src = GetRedirectSrc();
	
	// If we didn't find a short URL, then show the entire list
	if (src == undefined)
	{
		RedirectAnalytics(undefined);
		ShowRedirectList();
	}
	// Else, try to find the long URL
	else
	{
		RedirectAnalytics(src);
		
		let match = LookupRedirectDst(src);
		
		// Found it
		if (match != undefined)
		{
			let visitInfo = SetCurrentVisit(src);
			
			// Show the description. This is always visible
			document.getElementById("ID_Desc").innerHTML = match.desc;
			
			// Populate the destination URL. This is hidden.
			document.getElementById("ID_Dst").innerHTML = match.dst;

			// Set the link destination URL and show the image/button
			document.getElementById("ID_Link").href = match.dst;
			document.getElementById("ID_Link").classList.remove("hide-element");

			// Show the button that allows you to show the URL
			document.getElementById("ID_Show").classList.remove("hide-element");
			
			// Add a click handler to show the URL
			document.getElementById("ID_Show").addEventListener("click", ToggleShowDst);
			
			// Determine if we should redirect or stay on the page.
			// We stay on the page if we have visited the same short URL within the last 5 minutes
			if (priorVisit == undefined || priorVisit.src != visitInfo.src || visitInfo.ts - priorVisit.ts > 5*60*1000)
			{
				let cancel    = document.getElementById("ID_Cancel");
				let countdown = document.getElementById("ID_Countdown");
				let path      = document.getElementById("ID_CountdownPath");
				let link      = document.getElementById("ID_Link");
				
				// Immediate Redirect
				// -- too fast -- link.click();
				// Use a 1 sec timer to give the analytics a chance to get recorded
				// -- missing feedback -- setTimeout(() => link.click(), 1000);
				
				// Used for animationEnd event
				var FollowLink = function() { 
					// Hide the cancel button
					cancel.classList.add("app-icon-inactive");
					// Show the link button with a green background
					path.setAttribute("fill", "#0f0");
					// Redirect
					link.click();
				}
				
				// Cancel button function; undoes everything set-up below
				var CancelRedirectCountdown = function() { 
					countdown.removeEventListener("animationend", FollowLink);
					countdown.classList.remove("app-svg-countdown");
					countdown.classList.add("invisible-element");
					cancel.classList.add("app-icon-inactive");
					cancel.removeEventListener("click", CancelRedirectCountdown);
				}
				
				// Show the cancel button and add a click handler
				cancel.classList.remove("hide-element");
				cancel.addEventListener("click", CancelRedirectCountdown);
				// Show the SVG green ring and animate it counting down
				countdown.classList.remove("invisible-element");
				countdown.classList.add("app-svg-countdown");
				// Add a handler to follow the link when the animation ends
				countdown.addEventListener("animationend", FollowLink);
			}
			else
			{
				// Stay on page
			}
			
		}
		else
		{
			document.getElementById("ID_Desc").innerHTML = "Unknown short URL";
		}
	}
}


//------------------------------------------------------------------------------
//	Window OnLoad
//
//	Calls LaunchSystemMgr
//------------------------------------------------------------------------------
window.addEventListener("load", WindowLoaded);
